import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import ProductForm from './ProductForm'
import { breakpoints, colors, fonts, spacing } from 'utils/styles'
import { convertToMoney } from 'utils/priceFormat'

const ProductSpecs = props => {
  const {
    setSelectedIndex,
    selectedIndex,
    product: {
      id,
      name,
      metadata: {
        nick_name
      },
      description,
      skus,
      hasVariants,
      skus: [variant]
    }
  } = props

  const { price } = skus[selectedIndex]

  return (
    <ProductSpecsRoot>
      <Link to="/plants"><Type>plants</Type></Link>
      <Name>{nick_name ? nick_name : name}</Name>
      <SubName>{nick_name && name}</SubName>
      <Price>
        {convertToMoney(price)}
      </Price>
      <ProductForm id={id} variants={skus} hasVariants={hasVariants} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex}/>
    </ProductSpecsRoot>
  )
}

const ProductSpecsRoot = styled(`div`)`
  padding: 0 ${spacing.md}px;

  @media (min-width: ${breakpoints.tablet}px) {
    padding: ${spacing['2xl']}px ${spacing.xl}px 0;
  }
`

const Name = styled(`h1`)`
  color: #222;
  font-family: ${fonts.heading};
  font-size: 1.8rem;
  font-weight: 700;
  margin: 0;
`

const SubName = styled(Name)`
  color: #ced4da;
  font-family: ${fonts.heading};
  font-size: 1.4rem;
  line-height: 1.4rem;
  font-weight: 500;
  margin-bottom: 10px;
`

const Description = styled(`p`)`
  color: ${colors.text};
  font-size: 1rem;
  line-height: 1.5;
`

const Price = styled(`div`)`
  font-weight: 400;
  line-height: 1.4;
  color: #0a0a0a;
  font-size: 1.8rem;
  letter-spacing: -0.02em;
`

const Type = styled(`div`)`
  font-weight: 400;
  color: #0a0a0a;
  font-size: .8rem;
  text-transform: uppercase;
  letter-spacing: .1em;
  margin-bottom: 20px;
`

ProductSpecs.propTypes = {
  product: PropTypes.object.isRequired
}

export default ProductSpecs
