import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Img from 'gatsby-image'

import ProductSpecs from './ProductSpecs'
import DeliveryBanner from 'components/DeliveryBanner'
import Slideshow from 'components/shared/Slideshow'
import { breakpoints, spacing } from 'utils/styles'
import RecommendedPots from './RecommendedPots'
import RecommendedSimilar from './RecommendedSimilar'
import About from './About'
import { ProductsContext } from 'context/ProductsProvider'
import { RoomsSection } from 'components/HomePage'

const ProductPage = ({ productId, data }) => {
  const { products, listProducts } = useContext(ProductsContext)
  const product = products[productId]
  const { type } = product.metadata
  const recommended = listProducts(type,null,true).slice(0,4)

  const [selectedIndex, setSelectedIndex] = useState(0)

  const size = product.skus[selectedIndex].metadata.pot_bucket
  const [potSize, setPotSize] = useState(size)

  useEffect(() => {
    setPotSize(size)
  })
  
  return (
    <div style={{overflowX:'hidden'}}>
      <ProductPageRoot>
        <Container>
          <ImgContainer>
           <Slideshow
              data={product.images} />
          </ImgContainer>
          <Details>
            <ProductSpecs selectedIndex={selectedIndex} product={product} setSelectedIndex={setSelectedIndex}/>
          </Details>
        </Container>
      </ProductPageRoot>
      <About description={product.description} />
      <DeliveryBanner />
      {product.metadata.type=='plant' && <RecommendedPots size={potSize} data={data} /> }
      <RecommendedSimilar data={recommended} type={type} />
      <RoomsSection/>
    </div>
  )
}

const ProductPageRoot = styled('div')`
  padding-bottom: ${spacing.md}px;

  @media (min-width: ${breakpoints.desktop}px) {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 110px);
    padding: ${spacing.xl}px;
    width: 100%;
  }
`

const Container = styled(`div`)`
  @media (min-width: ${breakpoints.desktop}px) {
    align-items: flex-start;
    display: flex;
  }
`

const ImgContainer = styled('div')`
  @media (min-width: ${breakpoints.desktop}px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  margin-right: auto;
  margin-left: auto;
`

const Details = styled(`div`)`
  position: relative;
  flex: 0 0 50%;
  max-width: 100%;
  margin-left:auto;
  margin-right: auto;
  @media (min-width: ${breakpoints.desktop}px) {
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left:60px;
    max-width: 400px;
    min-height: 600px;
  }
`

export default ProductPage;
