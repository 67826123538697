import React from 'react'
import qs from 'qs'
import { Link } from 'gatsby'
import { fromJS } from 'immutable'

import styles from './RoomsSection.module.scss'
import { SUGGESTION_DATA } from './data.js'

export default function() {
  return (
    <div className={styles.suggestionContainer}>
      <div className={styles.sectionTitle}>Explore plants by room</div>
      <div className={styles.suggestionGrid}>
        {SUGGESTION_DATA.map(suggestion => (
          <Link
            to={suggestion.link}
            key={suggestion.title}
            className={styles.suggestionItem}
          >
            <img src={suggestion.image} className={styles.suggestionImg} />
            <h4 className={styles.suggestionTitle}>{suggestion.title}</h4>
            {/*<div className={styles.suggestionDescription}>
              {suggestion.description}
            </div>*/}
          </Link>
        ))}
      </div>
    </div>
  )
}
