import React from 'react'
import TweenOne from 'rc-tween-one'
import BezierPlugin from 'rc-tween-one/lib/plugin/BezierPlugin'

import snakeplant from '../../../../static/images/home/snake-plant-0.png'
import hebeandersonii from '../../../../static/images/home/hebe-andersonii-0.png'
import hydrandeaPaniculata from '../../../../static/images/home/hydrandea-paniculata-0.png'
import canaryIsland from '../../../../static/images/home/canary-island-0.png'
import bromeliad from '../../../../static/images/home/bromeliad-0.png'
import sempervirens from '../../../../static/images/home/buxus-sempervirens-0.png'
import gageana from '../../../../static/images/home/alocasia-gageana-0.png'
import siam from '../../../../static/images/home/siam-tulip-0.png'
import styles from './FloatingHeaderSection.module.scss'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import Button from 'components/shared/Button'

TweenOne.plugins.push(BezierPlugin)

const Demo = (props) => {
	const start = Math.floor(Math.random() * 20)
	const end = Math.floor(Math.random() * 20)
	const animation = {
      y: (Math.random() * 2 - 1) * 30 || 5,
      x: (Math.random() * 2 - 1) * 30 || 2.5,
      delay: Math.random() * 1000,
      repeat: -1,
      duration: 2500,
      yoyo: true,
      ease: 'easeInOutQuad',
    }
    return (
      <TweenOne
        animation={animation}
        component={Float}
        src={props.src}
      />
    )
}

const iconEntry = keyframes`
  0%, 50% {
    transform: scale(0);
  }
  90% {
  	opacity:1;
    transform: scale(1.2);
  }
  100% {
  	opacity:1;
    transform: scale(1);
  }
`

const Float = styled('img')`
	position: absolute;
	width: 90px;
	z-index: -1;
	&:nth-of-type(1) {
		left: 25%;
		top: 5%;
		@media (max-width: 900px) {
			left:15%;
			top: 75%;
		}
	}
	&:nth-of-type(even) {
		width:55px;
	}
	&:nth-of-type(2) {
		display: none;
		@media (min-width: 900px) {
			display:block;
			left:12%;
			top: 38%;
		}
	}
	&:nth-of-type(3) {
		display: none;
		@media (min-width: 900px) {
			display:block;
			right:14%;
			top: 20%;
		}
	}
	&:nth-of-type(4) {
		bottom: 2%;
		left: 32%;
		@media (max-width: 900px) {
			display:none;
		}
	}
	&:nth-of-type(5) {
		left: 57%;
		right: 40%;
		bottom: 0;
	}
	&:nth-of-type(6) {
		right: 4%;
		bottom: 5%;
		@media (max-width: 900px) {
			display: none;
		}
	}
	&:nth-of-type(7) {
		bottom: 38%;
		top: auto;
	}
	&:nth-of-type(8) {
		top: 9%;
		right: 4%;
		@media (max-width: 900px) {
			top: 25%;
			right: 15%;
		}
	}
	&:nth-of-type(9) {
		bottom: 4%;
		left: 10%;
		display:none;
		@media (min-width: 900px) {
			display:block;
			left:14%;
			top: 23%;
		}
	}
`

export default () => (
	<div className={styles.container}>
		<div className={styles.wrapper}>
			<div className={styles.background}>
				<Demo src={snakeplant} delay={1000}/>
				<Demo src={hebeandersonii} delay={1000}/>
				<Demo src={hydrandeaPaniculata} />
				<Demo src={canaryIsland} />
				<Demo src={bromeliad} />
				<Demo src={sempervirens} />
				<Demo src={gageana} />
				<Demo src={siam} />
			</div>
			<div className={styles.header}>
				<h1>Beautiful plants delivered</h1>
				<Button to="/plants" big>View all Plants</Button>
			</div>
		</div>
	</div>
)