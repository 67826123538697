import React from 'react'

import styles from './Guarentee.module.scss'
import MultiPoints from 'components/shared/MultiPoints'
import { QualityIcon, ShippingIcon, ReturnIcon } from 'components/shared/Icons'

const points = [
	{
		icon: <QualityIcon />,
		copy: 'Our selection of quality plants all pass our detailed health inspection.',
	},
	{
		icon: <ShippingIcon />,
		copy: 'On-time shipping by our drivers that works around your schedule.',
	},
	{
		icon: <ReturnIcon />,
		copy: 'Problem with your order? Free returns within 72 hours.',
	},
]

export default () => (
	<div className={styles.guarenteeSection}>
		<MultiPoints 
			title="Our Guarentee"
			points={points}/>
	</div>
)