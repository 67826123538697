import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { Fieldset, Input, Label, Select, Submit } from 'components/shared/FormElements'
import { breakpoints, colors, spacing, radius } from 'utils/styles'
import Link from 'components/shared/Link'
import { CartContext } from 'context/CartProvider'
import { convertToMoney } from 'utils/priceFormat'

const ProductForm = (props) => {
  const {
    variants,
    hasVariants,
    selectedIndex,
    setSelectedIndex
  } = props
  
  const [variantID, setVariantID] = useState(variants[selectedIndex].id)
  const [quantity, setQuantity] = useState(1)

  const changeVariant = (e) => {
    const new_index = e.target.value
    setVariantID(variants[new_index].id)
    setSelectedIndex(new_index)
  }

  const handleSubmit = callback => event => {
    event.preventDefault()
    callback(variantID, quantity)
  }

  const { add } = useContext(CartContext)

  // const isOutOfStock = !hasVariants && !variants[0].availableForSale
  return (
    <Form onSubmit={handleSubmit(add)} noValidate>
      {hasVariants && (
        <SizeFieldset>
          <Select
            id="variant"
            value={selectedIndex}
            name="variant"
            onChange={(e)=>changeVariant(e)}
          >
            <option disabled value="">
              Choose Size
            </option>
            {variants.map((variant,i) => (
              <option
                disabled={!variant.active}
                value={i}
                key={variant.id}
              >
                {variant.attributes.variants} — {convertToMoney(variant.price)}
              </option>
            ))}
          </Select>
        </SizeFieldset>
      )}
      <AddCart>
        <QtyFieldset>
          <Label htmlFor="quantity"></Label>
          <Input
            type="number"
            id="quantity"
            name="quantity"
            min="1"
            step="1"
            onChange={(e)=>setQuantity(e.target.value)}
            value={quantity}
          />
        </QtyFieldset>
        {/* <AddToCartButton */}
        {/*   type="submit" */}
        {/*   fullWidth={hasVariants} */}
        {/* > */}
        {/*   {'Add to Cart'} */}
        {/* </AddToCartButton> */}
      </AddCart>
      <InfoLinks>
      
      </InfoLinks>
    </Form>
  )
}

const Form = styled(`form`)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  @media (min-width: ${breakpoints.desktop}px) {
    justify-content: flex-start;
  
`

const QtyFieldset = styled(Fieldset)`
  flex-basis: 65px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 0px;

  ${Input} {
    padding: ${spacing.sm}px ${spacing.sm}px;
    text-align: center;
    border-radius: 4px 0 0 4px;
  }
`

const SizeFieldset = styled(Fieldset)`
  flex-basis: calc(100% - ${spacing.md}px - 70px);

  ${Label} {
    justify-content: space-between;
  }
`

const InfoLinks = styled(`div`)`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: ${spacing.lg}px;
  width: 100%;
`

const AddCart = styled(`div`)`
  display: flex;
`

const AddToCartButton = styled(Submit)`
  align-self: flex-end;
  flex-grow: 1;
  border-radius:0px 4px 4px 0;
  height: ${props => (props.fullWidth ? 'auto' : '')};
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
`

ProductForm.propTypes = {
  id: PropTypes.string.isRequired,
  variants: PropTypes.array.isRequired
}

export default ProductForm
