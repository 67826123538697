import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'layouts/Base'
import SEO from 'components/shared/SEO'
import ProductPage from '../components/ProductPage'
import { formatNodes } from 'utils/formatNodes'

function ProductPageTemplate({ pageContext: { id }, data }) {
  const bucket3 = formatNodes(data.bucket3)
  const bucket5 = formatNodes(data.bucket5)
  const bucket6 = formatNodes(data.bucket6)
  const bucket8 = formatNodes(data.bucket8)
  const bucket10 = formatNodes(data.bucket10)
  const bucket12 = formatNodes(data.bucket12)
  const bucket14 = formatNodes(data.bucket14)
  const bucket16 = formatNodes(data.bucket16)
  const buckets = {
    bucket3,
    bucket5,
    bucket6,
    bucket8,
    bucket10,
    bucket12,
    bucket14,
    bucket16
  }
  return (
    <ProductPage
      productId={id}
      data={buckets}
    />
  )
}

export default ({ data, location, pageContext }) => {
  return (
    <>
      <SEO title="Plants"/>
      <ProductPageTemplate pageContext={pageContext} data={data} />
    </>
  )
}

export const query = graphql`
  query ProductPageQuery {
    bucket3: allStripeSku(
      limit: 12, 
      filter: { metadata: { pot_bucket: { in: "3-5in" }}, product: {metadata:{ type: {in:"pot"}}}  }
    ) {
      group(field: product___id) {
        edges {
          node {
            ...Sku
          }
        }
      }
    }
    bucket5: allStripeSku(
      limit: 12, 
      filter: { metadata: { pot_bucket: { in: "5-6in" }}, product: {metadata:{ type: {in:"pot"}}}  }
    ) {
      group(field: product___id) {
        edges {
          node {
            ...Sku
          }
        }
      }
    }
    bucket6: allStripeSku(
      limit: 12, 
      filter: { metadata: { pot_bucket: { in: "6-8in" }}, product: {metadata:{ type: {in:"pot"}}}  }
    ) {
      group(field: product___id) {
        edges {
          node {
            ...Sku
          }
        }
      }
    }
    bucket8: allStripeSku(
      limit: 12, 
      filter: { metadata: { pot_bucket: { in: "8-10in" }}, product: {metadata:{ type: {in:"pot"}}}  }
    ) {
      group(field: product___id) {
        edges {
          node {
            ...Sku
          }
        }
      }
    }
    bucket10: allStripeSku(
      limit: 12, 
      filter: { metadata: { pot_bucket: { in: "10-12in" }}, product: {metadata:{ type: {in:"pot"}}}  }
    ) {
      group(field: product___id) {
        edges {
          node {
            ...Sku
          }
        }
      }
    }
    bucket12: allStripeSku(
      limit: 12, 
      filter: { metadata: { pot_bucket: { in: "12-14in" }}, product: {metadata:{ type: {in:"pot"}}}  }
    ) {
      group(field: product___id) {
        edges {
          node {
            ...Sku
          }
        }
      }
    }
    bucket14: allStripeSku(
      limit: 12, 
      filter: { metadata: { pot_bucket: { in: "14-16in" }}, product: {metadata:{ type: {in:"pot"}}}  }
    ) {
      group(field: product___id) {
        edges {
          node {
            ...Sku
          }
        }
      }
    }
    bucket16: allStripeSku(
      limit: 12, 
      filter: { metadata: { pot_bucket: { in: "16-18in" }}, product: {metadata:{ type: {in:"pot"}}}  }
    ) {
      group(field: product___id) {
        edges {
          node {
            ...Sku
          }
        }
      }
    }
  }
`