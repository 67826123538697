import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import Image from 'gatsby-image'

import './Slideshow.scss'
import './SlideshowThumbnails.scss'
import { Img } from 'utils/styles'

class SlideshowThumbnails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      totalTabs: 3,
      activeTab: 0,
    }
  }

  componentWillUpdate(_, nextState) {
    if (this.state.activeTab !== nextState.activeTab) {
      this.slider.slickGoTo(nextState.activeTab);
    }
  }

  changeHandler(e, index) {
    e.preventDefault()
    this.props.onChange(index)
    this.slider.slickGoTo(index)
  }

  render() {
    const { data } = this.props

    const settings = {
      accessibility: false,
      dots: false,
      arrows: false,
      slidesToShow: 3,
      draggable: false,
      centerMode: true,
      centerPadding: '30px',
    }

    const imgWidth = 241;
    const imgHeight = 150;

    return (
      <div className="slideshow-thumbnails">
        <Slider
          {...settings}
          ref={ref => {
            this.slider = ref
          }}
        >
          {data.map((img, i) => (
            <a
              key={i}
              className="slideshow-thumbnails__item"
              onClick={e => {
                e.preventDefault();
                this.changeHandler(e, i)
              }}
            >
              <Img
                fluid={img.localFile.childImageSharp.fluid}
              />
            </a>
          ))}
        </Slider>
      </div>
    )
  }
}

SlideshowThumbnails.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default SlideshowThumbnails







