import React, { useContext } from 'react'

import Recommended from 'components/Recommended'
import { ProductsContext } from 'context/ProductsProvider'
import { formatNodes } from 'utils/formatNodes'


export default ({size, data}) => {	
	let bucket = []
	switch (size) {
		case '3-5in':
			bucket = data.bucket3
			break;
		case '5-6in':
			bucket = data.bucket5
			break;
		case '6-8in':
			bucket = data.bucket6
			break;
		case '8-10in':
			bucket = data.bucket8
			break;
		case '10-12in':
			bucket = data.bucket10
			break;
		case '12-14in':
			bucket = data.bucket12
			break;
		case '16-18in':
			bucket = data.bucket16
			break;
	}
	return (
		<Recommended
			header="Need a pot? Fits great in"
			subheader={`[${size} width]`}
			data={bucket.slice(0,4)}
			cta={{title:"View all pots",link: "/pots"}}/>
	)
}