import React, { Component } from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'
import Image from 'gatsby-image'

import './Slideshow.scss'
import SlideshowThumbnails from './SlideshowThumbnails'
import { ChevronRight , ChevronLeft } from 'components/shared/Icons'
import { Img } from 'utils/styles'


/* eslint-disable react/prop-types */
const Arrow = ({ currentSlide, slideCount, type, ...props }) => (
  <button {...props}>
    {type === 'prev' ? <ChevronLeft className="icon"/> : <ChevronRight className="icon"/>}
  </button>
);
/* eslint-enable */

class Slideshow extends Component {
  constructor(props) {
    super(props);

    this.onKeyPress = this.onKeyPress.bind(this);
  }

  componentDidMount() {
    this.onAppear();
  }

  /* eslint-disable no-unused-expressions */
  componentWillReceiveProps(nextProps) {
    if (this.props.isVisible !== nextProps.isVisible) {
      nextProps.isVisible ? this.onAppear() : this.onDisappear()
    }
  }
  /* eslint-enable */

  onAppear() {
    document.addEventListener('keydown', this.onKeyPress)
  }

  onDisappear() {
    document.removeEventListener('keydown', this.onKeyPress)
  }

  onKeyPress(e) {
    switch (e.which) {
      case 37: // left
        this.slider.slickPrev()
        break

      case 39: // right
        this.slider.slickNext()
        break

      default: // exit this handler for other keys
    }
  }

  focus() {
    this.slider.innerSlider.list.setAttribute('tabindex', 0)
    this.slider.innerSlider.list.focus()
  }

  changeHandler(index) {
    this.goTo(index)
  }

  goTo(index) {
    this.slider.slickGoTo(index);
  }

  render() {
    const { data } = this.props;

    const settings = {
      accessibility: false,
      dots: false,
      slidesToShow: 1,
      speed: 500,
      nextArrow: <Arrow type="next" />,
      prevArrow: <Arrow type="prev" />,
    }

    return (
      <div
        className="slideshow"
        ref={ref => {
          this.container = ref;
        }}
      >
        <div className="slideshow__photos">
          <Slider
            {...settings}
            ref={ref => {
              this.slider = ref;
            }}
          >
            {data.map((img,i) => (
              <img src={img}/>
            ))}
          </Slider>
        </div>
      </div>
    );
  }
}

Slideshow.defaultProps = {
  isVisible: null,
  currentSlide: undefined,
  slideCount: undefined,
  type: undefined,
};

Slideshow.propTypes = {
  currentSlide: PropTypes.number,
  slideCount: PropTypes.number,
  type: PropTypes.string,
  isVisible: PropTypes.bool,
};

export default Slideshow;
