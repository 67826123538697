import React, { useContext } from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'

import styles from './FeaturedItems.module.scss'
import { RightChevron } from 'components/shared/Icons'
import ProductListingItem from 'components/ProductListing/ProductListingItem'
import SmoothSlider from 'components/shared/Slider/SmoothSlider'
import { ProductsContext } from 'context/ProductsProvider'


const FavoriteSlider = ({products}) => {
  return (
    <SmoothSlider>
       {products.map((product,i) => (
         <div key={i} className="slider-item">
          <ProductListingItem  product={product} />
        </div>
      ))}
    </SmoothSlider>
  )
}

export default (props) => {
  const { listProducts } = useContext(ProductsContext)
  const products = listProducts('plant')
  return (
    <section className={styles.featuredSection}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>Our Best Sellers</div>
          <div className={styles.viewAll}>
            <Link
              to={`/plants`}
            >
              View all
              <RightChevron />
            </Link>
          </div>
        </div>
        <div className={styles.sliderWrapper}>
          <FavoriteSlider products={products.slice(0,10)} />
        </div>
    </div>
    </section>
  )
  
}
