import React from 'react'

import Recommended from 'components/Recommended'

export default ({data,id, type}) => {
	let cta = {title:'View all plants', link:'/plants'}
	if (type=='pot') {
		cta = {title:'View all pots', link:'/pots'}
	}
	return (
		<Recommended
			header="Why not try?"
			data={data}
			cta={cta} />
	)
}