import bed from '../../../../static/images/bedroom/bed-nav-square.jpeg'
import bookshelf from '../../../../static/images/living-room/bookshelf-nav-square.jpeg'
import balcony from '../../../../static/images/patio/balcony-nav-square.jpeg'
import sink from '../../../../static/images/kitchen/sink-nav-square.jpeg'
import backyard from '../../../../static/images/patio/backyard-nav-square.jpeg'

export const SUGGESTION_DATA = [
  {
    image: bookshelf,
    title: 'Living Room',
    link: 'indoor/living-room',
    description: 'Spice up your kitchen with some greenery.',
  },
  {
    image: bed,
    title: 'Bedroom',
    link: 'indoor/bedroom',
    description: 'Bring soothing plants back to your place of rest.',
  },
  {
    image: sink,
    title: 'Kitchen',
    link: 'indoor/kitchen',
    description: 'Discover a new way to get around',
  },
  {
    image: balcony,
    title: 'Balcony',
    link: 'outdoor/balcony',
    description: 'Make the most of the great outdoors',
  },
  {
    image: backyard,
    title: 'Patio',
    link: 'outdoor/patio',
    description: 'Get a greater view from above',
  }
]
