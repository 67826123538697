import React from 'react'

import styles from './SmoothSlider.module.scss'

export default ({ children }) => (
  <div className={styles.container}>
    <div className={styles.cards}>{children}</div>
    <div className={styles.shadowCurtain} />
  </div>
)
