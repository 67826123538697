import React, { useContext } from 'react'
import { StaticQuery, graphql } from 'gatsby'

import styles from './BedroomSection.module.scss'
import HeaderImage from 'components/HeaderImage'
import Button from 'components/shared/Button'
import { CartContext } from 'context/CartProvider'

const ActionSection = () => {
	const { add } = useContext(CartContext)
	const skus = [{id:'sku_FXqhhwh1mITrw6', amount:3},{id:'sku_FXqga1Gxfjebxx',amount:1},{id:'sku_FXqgMcyqC437ff',amount:1},{id:'sku_FXqgaMVAw7YeCZ',amount:1}]
	const addToBag = () => {
		skus.map((sku)=>{
			add(sku.id,sku.amount)
		})
	}
	return (
		<div>
			<h1 className={[styles.heroTitle].join(' ')}>The Apartment Starter Kit</h1>
			<div className={[styles.heroCopy].join(' ')}>
				Get started off on the right foot. Decorating your new apartment with urban jungle vibes has never been easier.
			</div>
			<div className={styles.actionContainer}>
				<Button big onClick={()=>addToBag()}>Add to Cart</Button>
			</div>
		</div>
	)

}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "apartment-starter.png" }) {
		  childImageSharp {
		    fluid(maxWidth: 700) {
		      ...GatsbyImageSharpFluid_withWebp
		    }
		  }
		}
      }
    `}
    render={data =>
	<HeaderImage 
		reverse
		containerClass={styles.container}
		size="medium"
		collection={<ActionSection />}
		image={data.file.childImageSharp.fluid}/>
	}/>
)